import axios from 'axios'
import repository from './repository'

const appAxios = axios.create({ baseURL: process.env.VUE_APP_SERVER_DOMAIN })

export default {
  login (name, password) {
    return appAxios.post('/api/auth/login', { name, password })
  },
  adminData (params) {
    return repository.get('admin-data', { params })
  },
}
