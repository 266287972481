<template lang="">
    <!-- <v-parallax :src="spaceBackground"></v-parallax> -->
  <v-card
    elevation="4"
    style="max-width: 400px; margin-top: 10vh;"
    class="mx-auto"
  >
    <v-toolbar
      color="indigo"
      dark
    >
      <v-card-title
        style="-webkit-app-region: drag"
        class="pa-0"
      ><span>Welcome</span></v-card-title>
      <v-spacer></v-spacer>
      <v-icon>mdi-briefcase</v-icon>
    </v-toolbar>
    <v-container
      style="-webkit-app-region: no-drag"
    >
      <v-row>
        <v-col
          cols="12"
        >
          <!-- <v-alert
            dense
            type="error"
            outlined
            text
            dismissible
            transition="slide-x-transition"
            class="py-0 text-uppercase"
            v-if="sessionExpired"
          >
            Your session have expired pls relogin to continue your work
          </v-alert> -->
          <v-alert
            dense
            type="error"
            outlined
            text
            dismissible
            transition="slide-x-transition"
            class="py-0 text-uppercase"
            v-for="(item, index) in loginPostErrors"
            :key="index"
          >
            {{ item.first() }}
          </v-alert>
          <v-text-field
            v-model="name"
            prepend-icon="mdi-account"
            label="Username"
            required
          ></v-text-field>
        </v-col>
        <v-col
        cols="12"
        >
          <v-text-field
            v-model="password"
            :type="!showPassword ? 'password' : 'text'"
            label="Password"
            prepend-icon="mdi-lock"
            :append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            required
          ></v-text-field>
        </v-col>
        <v-col
        cols="12"
        >
          <v-btn
            @click="login"
            color="indigo"
            :loading="loginPosting"
            dark
          >
            Login
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { errorHandler, postVars } from '@/libs/api-helper.extra'
import authRepository from '@/repositories/auth.repository'

const inputVars = () => ({
  name: null,
  password: null,
  showPassword: false,
})

export default {
  name: 'Login',
  data () {
    return {
      ...inputVars(),
      ...postVars('login'),
    }
  },
  computed: {
    ...mapState({
      token: state => state.auth.token,
    }),
  },
  watch: {
    token (val) {
      // if (!val) return
      // location.replace('/')
    },
  },
  methods: {
    ...mapActions({
      setToken: 'auth/setToken',
      setMainPage: 'auth/setMainPage',
      setUser: 'auth/setUser',
      setAccessLevel: 'auth/setAccessLevel',
      wsSetToken: 'websocket/setToken',
    }),
    async login () {
      if (this.loginPosting) return
      this.loginPosting = true
      this.loginPostErrors = []
      authRepository.login(this.name, this.password)
        .then(({ data }) => {
          const { token = null, mainPage = null, user = null, accessLevel = null } = Window.objectChangeKeyCase(data) || {}
          // this.$cookies.set('token', this.encryptToken(token), '2d')
          this.setToken(token)
          this.setMainPage(mainPage)
          this.setUser(user)
          this.setAccessLevel(accessLevel)
          this.wsSetToken(token)
          location.replace('/')
        })
        .catch(e => { this.loginPostErrors = errorHandler(e) })
        .then(() => { this.loginPosting = false })
    },
    encryptToken (token) {
      return this.$CryptoJS.AES.encrypt(token, process.env.VUE_APP_COOKIE_SECRET).toString()
    },
  },
}
</script>
